import "./Scroll.css"
import React, {useState} from "react"
import { Link } from "react-scroll";
import { useScroll } from "react-spring";


const Scroll = () => {
    const [offset, setOffset] = useState(-220)

    return (
        <Link to="aboutMe-container" smooth={true} duration={500} offset={offset}>
            <div className="scroll-container" onClick={() => {setOffset(-130)}}>
                <i class="uil uil-mouse-alt-2 mouse"></i>
                <span className="scroll-text">See More</span>
                <i className="uil uil-arrow-down scroll-arrow"></i>
            </div>
        </Link>
    );
};

export default Scroll;