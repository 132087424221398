import React from 'react';
import AnimatedWrapper from '../Cards/Animate';
import WideCard from '../Cards/WideCard';
import "./Education.css"

const Education = () => {

    return (
        <div>
            <div id="education">
                <div id="education-title">
                    <span className="education-highlighted-text">EDUCATION</span>
                </div>
                <AnimatedWrapper Component={WideCard}
                title = "Nan Hua High School"
                description = {["Subjects taken: English, Chinese, AMath, EMath, Physics, Chemistry, Biology, Combined Humanities (SS + Geography)" ,"Top 30 in level in 2018 (Secondary 3)", "Attained Bronze for Singapore Mathematical Olympiad (Junior) 2017"]}
                timeframe = "Jan 2016 - Nov 2019"
                />
                <AnimatedWrapper Component={WideCard}
                title = "Hwa Chong Institution"
                description = {["Subjects taken: H2 Physics, H2 Chemistry, H2 Mathematics, H2 Economics", "Attained Distinction for all 4 H2s", "Top 5% for H2 Mathematics in 2020 Promotional Examination"]}
                timeframe = "Jan 2020 - Nov 2021"
                />
                <AnimatedWrapper Component={WideCard}
                title = "NUS"
                description = {["Enrolling into National University of Singapore in 2024", "Pursued degree: Bachelor of Computing (Honours) in Computer Science"]}
                timeframe = "Aug 2024 - July 2028"

                />
        </div>
    </div>
    );
  };
  


export default Education;