import "./AboutMe.css"
import React from "react"
import CodeCard from "./CodeCard"
import AboutMeCard from "./AboutMeCard"
import AnimatedWrapper from "../Cards/Animate"


const AboutMe = () => {
    return(
        <div id="aboutMe-container">
            <div className="aboutMe-header">
                <span className="aboutMe-highlighted-text">ABOUT ME</span>
            </div>
            <div className="resume-container">
                <a
                href="/alex_guo_resume_copy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="resume-button"
                >
                Download Resume <i class="uil uil-file"></i>
                </a>
            </div>
            <div className="AboutMeCards-container">
                <AboutMeCard title="1" subtitle="Years of Coding" iconElement={<i class="uil uil-brackets-curly"></i>}/>
                <AboutMeCard title="5" subtitle="Projects" iconElement={<i class="uil uil-flask-potion"></i>}/>
                <AboutMeCard title="0" subtitle="Tech Internships" iconElement={<i class="uil uil-bag-alt"></i>}/>
            </div>
            <AnimatedWrapper Component={CodeCard} />
        </div>
    )
}

export default AboutMe