import "./Projects.css"
import React, {useEffect, useState} from 'react';
import LongCard from "../../Cards/LongCard";
import SkillClick from "../SkillClick.jsx"
import CarouselMain from "../CarouselMain";


function Projects(props) {

  // logic for SkillClick
  const skills = [
    { color: '#f08080', skillName: 'web app' },
    { color: '#9ad1d4', skillName: 'python' },
    { color: '#d2d2d2', skillName: 'development' },
    { color: '#badefc', skillName: 'bot/automation' },
    { color: 'gold', skillName: 'algorithm' }
  ];
  
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleSkillClick = (index) => { //callback func
    if (index === selectedIndex) {
      setSelectedIndex(-1); 
    } else {
      setSelectedIndex(index);
    }
  };

  // end of logic

    return (
        <div id='projects-container'>
        <div id="project-title">
          <span className="project-highlighted-text">PROJECTS</span>
        </div>
          <div className="projectHeader">
            A collection of personal and passion projects
          </div>
          <div className="skills-row">
              {skills.map((skill, index) => (
                <SkillClick
                  key={index}
                  color={skill.color}
                  skillName={skill.skillName}
                  selected={index === selectedIndex}
                  onClick={() => handleSkillClick(index)}
                />
              ))}
          </div>
          <CarouselMain/>
          {/* <div id="cards">
          <div className="projectCards">
              <LongCard title="StudyLAH!" image="/studyLah.png" headerColor="linear-gradient(45deg, rgb(186, 222, 252), rgb(234, 234, 234))"  
              description="developed a web app which aims to function as an open-source academic repository, catering to students from various academic levels - during NUS LifeHack 2023"
              link="https://github.com/aliensarefake/hackathon"
              skills={[
                { name: 'web app', color: '#f08080' },
              ]}/>
              <LongCard title="BudgetBuddy Bot" image="/tele_bot.png" headerColor="#F08080"
              description="telegram bot which I use to manage and track my finances"
              link="https://github.com/aliensarefake/expensesBot"
              skills={[
                { name: 'python', color: '#9ad1d4' },
                { name: 'development', color: '#d2d2d2'},
                { name: 'bot/automation', color: '#badefc'}
              ]}          
              />
            </div>
            <div className="projectCards">
            <LongCard title="Project Blueshield" image="blueshield.png" headerColor="linear-gradient(45deg, rgb(154, 209, 212), rgb(234, 234, 234), rgb(255, 192, 203))"
              description="website for Stirling View Zone Residents' Committee Centre to publicize its mission, and to recruit volunteers "
                skills={[
                  { name: 'web app', color: '#f08080' },
                  { name: 'development', color: '#d2d2d2'}
                ]}            
              />
              <LongCard title="SCDF E-Checklist" image="/automateChecklist.png" headerColor="linear-gradient(45deg, rgb(234, 234, 234), rgb(255, 192, 203))"
              description="automated the filling up of the formSG checklists for fire appliances, a task which would normally take an average of 20 minutes when done manually"
              link="https://github.com/aliensarefake/E-Checklist-Automation"
                skills={[
                  { name: 'python', color: '#9ad1d4' },
                  { name: 'bot/automation', color: '#badefc'}
                ]}       
              />
            </div>         
            <div className="projectCards">
            <LongCard title="Sudoku Solver" image="sudoku-webapp.png" headerColor="linear-gradient(45deg, rgb(154, 209, 212), rgb(234, 234, 234), rgb(255, 192, 203))"
              description="developed this web app to visualise how the backtracking algorithm navigates through diffferent possibilities to solve the puzzle in a brute-force manner"
              link="https://github.com/aliensarefake/Sudoku-Solver/"
                skills={[
                  { name: 'web app', color: '#f08080' },
                  { name: 'algorithm', color: 'gold'}, 
                  { name: 'development', color: '#d2d2d2'},
                ]}            
              />
              <LongCard title="SCDF E-Checklist" image="/automateChecklist.png" headerColor="linear-gradient(45deg, rgb(234, 234, 234), rgb(255, 192, 203))"
              description="automated the filling up of the formSG checklists for fire appliances, a task which would normally take an average of 20 minutes when done manually"
              link="https://github.com/aliensarefake/E-Checklist-Automation"
                skills={[
                  { name: 'python', color: '#9ad1d4' },
                  { name: 'bot/automation', color: '#badefc'}
                ]}       
                opacity={0}
              />
            </div>         
          </div> */}
        </div>     

    );
  }
  
  export default Projects;
  