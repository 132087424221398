import React from 'react';
import "./JobCard.css"

const JobCard = (props) => {
    const {id} = props
    const dynamicStyle = id % 2 == 1 ? 'left': "right"
    return(
        <div className={`jobCardWrapper ${dynamicStyle}`}>
            <div className='jobContent'>
                <div className='jobDescription'>
                    <div className='companyName'>{props.companyName}</div>
                    <div className='jobTitle'>{props.jobTitle}</div>
                </div>
                <div className='jobImg'>
                    <img className='jobLogo' src={props.img}></img>
                </div>
            </div>
            <div className='jobDuration'>
                <i class="uil uil-calendar-alt"></i> {props.duration}
            </div>
        </div>
    )
}

export default JobCard;
