import "./InputField.css"
import React, { useState, useEffect } from 'react';

const InputField = ({ title, value, onChange, isValid }) => {
    let containerClass = `${title === "Message" ? "input message" : "input"}`;

    const [shouldShake, setShouldShake] = useState(false);
    useEffect(() => {
      if (!isValid) {
        setShouldShake(true);
        // Remove the shake class after 500ms (same duration as your shake animation)
        setTimeout(() => setShouldShake(false), 1000);
      }
    }, [isValid]);
    
    if (shouldShake) {
      containerClass += ' invalid-border shake';
    }

    return (
      <div className="input-container">
        <label className="label" htmlFor="myInput">{title}</label>
        {title === "Message" ? 
          <textarea id="myInput" className={containerClass} value={value} onChange={onChange}></textarea> :
          <input id="myInput" className={containerClass} type="text" value={value} onChange={onChange} />
        }
      </div>
    )
  }
  
  export default InputField;