import { TypeAnimation } from 'react-type-animation';
import "./TypingIntro.css"

const TypingIntro = () => {
    return (
      <div className='typing-container'>
        <TypeAnimation
          sequence={[
            'welcome!',
            1000,
            '',
            650, 
            'my name is Alex',
            1000,
            '',
            650, 
            'i am a prospective NUS CS student',
            1000,
            '',
            650, 
            'scroll below to learn more about me:',
            1000,
            '',
            650, 
            'thank you for visiting my page :)',
            1000
          ]}
          wrapper="span"
          speed={40}
          deletionSpeed={60}
          style={{
            // fontSize: '18px',
            display: 'block',
            textAlign: 'center', 
            color: 'rgb(54, 69, 79)',
            fontFamily: "'Fira Code', monospace", 
            opacity: "0.8"
          }}
          repeat={Infinity}
        />
      </div>
    );
  };
  


export default TypingIntro