import "./SkillDisplay.css"
import React from 'react';


function SkillDisplay(props) {
    const color = props.color
    return (
        <span className="skillD-name" style={{ background: color }}>
            {props.skillName}
        </span>
    )
  }
  
  export default SkillDisplay;
  