import "./ContactCard.css"
import React from "react"

const ContactCard = (props) => {
    const handleClick = () => {
        const link = props.link
        window.open(link, '_blank');
    }

    return(
    <div className="contactCard-container">
        <div className="contacCard-logo">
            {props.iconElement}
        </div>
        <h3 className="contactCard-title">
            {props.title}
        </h3>
        <span className="contactCard-subtitle">
            {props.subtitle}
        </span>
        <span className="arrow-container" onClick={handleClick}>
            <i class="uil uil-angle-right-b arrow"></i>
        </span>
    </div>
    )
}

export default ContactCard;