import React from 'react';
import './Experience.css';
import JobCard from './JobCard';


const Experience = () => {

  const experiences = [
  //   {
  //     id: 1,
  //     companyName: "NodeFlair",
  //     jobTitle: "Software Engineer Intern",
  //     duration: "Feb 2024 - July 2024",
  //     img: "/Nodeflair_Logo.png"
  // },
      {
        id: 1,
        companyName: "Switcheo Labs",
        jobTitle: "Software Engineer Intern",
        duration: "Nov 2023 - Jan 2024",
        img: "/Switcheo_Logo.png"
    },

    {
      id: 2,
      companyName: "Singapore Civil Defence Force",
      jobTitle: "Emergency Medical Technician (NS)",
      duration: "Mar 2022 - Nov 2023",
      img: "/SCDF_Logo.jpeg"
  },
];

  return (
    <React.Fragment>
        <div id="experience-header">
          <span className="experience-highlighted-text">EXPERIENCES</span>
          <div className='experience-content'>
            {
              experiences.map((experience) =>{
                return(
                  <JobCard companyName={experience.companyName} 
                  jobTitle={experience.jobTitle}
                  duration={experience.duration}
                  img={experience.img}
                  key={experience.id}
                  id={experience.id}
                  />
                )

              })
            }
          </div>
        </div>

    </React.Fragment>
  );
};

export default Experience;