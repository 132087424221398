import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-okaidia.css';
import "./CodeCard.css"

const CodeCard = () => {
  const codeString = `
<script>
    let aboutMe = {
        name: "Alex Guo",
        age: "20",
        major: "Computer Science",
        expGrad: "July 2028",
        hobbies: [
            "volleyball",
            "trekking",
            "cycling",
            "cafe hopping"
        ],
        careerInterests: [
            "fullstack developer",
            "ml engineer",
            "quantitative trader"
        ],
        programming: [
            "Python",
            "Javascript",
            "TypeScript",
            "HTML/CSS",
            "MERN Stack"
        ],
        contact: {
            email: "lexguo2003@gmail.com",
            github: "aliensarefake",
            linkedIn: "guoxiaoyu",
        },
        greet: function() {
            console.log("Hey there! I'm " + this.name);
        }
    };
</script>`;

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className="code-interface">
      <div className="mac-buttons">
        <div className="button red"></div>
        <div className="button yellow"></div>
        <div className="button green"></div>
      </div>
      <pre className="code-block">
        <code className="language-javascript">
          {codeString}
        </code>
      </pre>
    </div>
  );
};

export default CodeCard;
