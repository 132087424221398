import React from 'react';
import "./WideCard.css"


const WideCard = (props) => {

    return (
        <div className={'wideCard-container'}>
          <div className='wideCard-header-color' style={{ background: "linear-gradient(45deg, rgb(234, 234, 234), rgb(186, 222, 252), rgb(255, 192, 203))" }}></div>
            <div className='wideCard-title'>
                <span className="title-text">
                    {props.title}
                </span>
                <span className="time-frame">
                    {props.timeframe}
                </span> 
            </div>
            <div className="wideCard-description">
                <ul>
                    {props.description.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
      </div>
    );
};

export default WideCard;
