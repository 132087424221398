import { useRef, useEffect } from 'react';
import { motion, useAnimation, useInView} from 'framer-motion';

const AnimatedWrapper = ({ Component, ...props }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const control = useAnimation();

  useEffect(() => {
    if (isInView) {
        console.log("in view");
      control.start('visible');
    } else{
      control.start('hidden')
    }
  }, [isInView, control]);


  return (
    <div ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 125 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={control}
        transition={{ duration: 0.3, delay: 0.2}}
      >
        <Component {...props} />
      </motion.div>
    </div>
  );
};

export default AnimatedWrapper;
