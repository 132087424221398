import React, {useState} from 'react';
import './Form.css';
import ContactCard from './ContactCard';
import InputField from './InputField';

const Form = () => {
  
  // form validation
  const [validationState, setValidationState] = useState({
    name: true,
    email: true,
    message: true
  });

  const validateFields = () => {
    let isValid = true;
    const newValidationState = {
      name: true,
      email: true,
      message: true
    }

    // Name should not be empty
    if (!formData.name) {
      newValidationState.name = false;
      isValid = false;
    }

    // Validate email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      newValidationState.email = false;
      isValid = false;
    }

    // Message should not be empty
    if (!formData.message) {
      newValidationState.message = false;
      isValid = false;
    }

    setValidationState(newValidationState);
    return isValid;
  };
  // end of logic

  // Handling data for form submission
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const [shakeKey, setShakeKey] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitText, setSubmitText] = useState("Send Message");
  const handleSubmit = async () => {
    if (validateFields()) {
      console.log(formData)
      setIsLoading(true); 
      try {
        console.log("INSIDE")
        const response = await fetch('https://alex-personal-webapp-8028d4a16782.herokuapp.com/api/submitForm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.success) {
          setIsSubmitted(true);
          setSubmitText("Message Sent");
        } else {
          setSubmitText("Failed to send message");
        }

        setIsLoading(false); 
        // Reset form fields
        setFormData({
          name: '',
          email: '',
          message: ''
        });
        setTimeout(() => setSubmitText("Send Message"), 5000);
  
      } catch (error) {
        setIsLoading(false); 
        console.error('There was a problem submitting the form', error);
      }
    } else {
      setShakeKey(shakeKey + 1);
      setIsSubmitted(false);
    }
  };

  // end of logic

    return (
      <div id="form-container">
        <div id="contact-title">
            <span className="contact-highlighted-text">CONTACT</span>
        </div>
        <div className="contact-description"> Here's how you can reach me</div>
          {/* {
            isSubmitted && <p>Form submitted successfully!</p>
          } */}
        <div className="form-grid">
          <div className="grid-item header column-1">Connect with me</div>
          <div className="grid-item header column-2">Leave a message</div>
          <div className="grid-item column-1">
          <ContactCard
          iconElement={<i class="uil uil-envelope-alt logo"></i>}
          title="Email"
          subtitle="lexguo2003@gmail.com"
          link = "mailto:lexguo2003@gmail.com"
          />
          </div>
          <div className="grid-item name-email column-2">
          <InputField key={`name-${shakeKey}`} title="Name" value={formData.name} onChange={(e) => handleChange(e, 'name')} isValid={validationState.name}/>
          <InputField key={`email-${shakeKey}`} title="Email" value={formData.email} onChange={(e) => handleChange(e, 'email')} isValid={validationState.email}/>
          </div>
          <div className="grid-item column-1">
          <ContactCard
          iconElement={<i class="uil uil-phone logo"></i>}
          title="Phone"
          subtitle="+65 92997869"
          link="https://api.whatsapp.com/send?phone=6592997869"
          />
          </div>
          <div className="grid-item message column-2">
            <InputField key={`message-${shakeKey}`} title="Message" value={formData.message} onChange={(e) => handleChange(e, 'message')} isValid={validationState.message}/>
          </div>
          <div className="grid-item column-1 address">
          <ContactCard
          iconElement={<i class="uil uil-location-point logo"></i>}
          title="Address"
          subtitle="21 Lower Kent Ridge Road"
          link="https://www.comp.nus.edu.sg/programmes/ug/cs/"
          />
          </div>
          <div className="grid-item submit column-2">
            <div className='submit-container' onClick={handleSubmit}>
            {isLoading ? (
              <div className="loader"></div>
              ) : (
                <>
                  <span className="submit-text">{submitText}</span>
                  {submitText === "Send Message" ? 
                    <i className="uil uil-navigator send-arrow"></i> : 
                    <i className="uil uil-check-circle submit-success"></i>
                  }
                </>
              )}     
          </div>
          </div>
        </div>
      </div>
    );
  };


export default Form;