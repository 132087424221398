import "./SkillClick.css"
import React from 'react';


function SkillClick(props) {
    const color = props.color

    return (
        <span className={`skillC-name ${props.selected ? 'selected' : ''}`} style={{ background: color }} onClick={props.onClick}>
            {props.skillName}
        </span>
    )
  }
  
  export default SkillClick;
  