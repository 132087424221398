// LandingPage.jsx
import React from 'react';
import Greeting from './Greeting';
import TypingIntro from './TypingIntro';
import AboutMe from '../AboutMePortion/AboutMe';
import Education from './Education';
import AnimatedWrapper from '../Cards/Animate';
import Scroll from './Scroll';
import Projects from '../ProjectPortion/Projects Component/Projects';
import Experience from './Experience/Experience';
import Form from '../FormPortion/Form';

const LandingPage = (props) => {
  return (
    <React.Fragment>
        <Greeting />
        <TypingIntro />
        <Scroll/>
        <AnimatedWrapper Component={AboutMe} />
        <AnimatedWrapper Component={Education} />
        <AnimatedWrapper Component={Projects} />
        <AnimatedWrapper Component={Experience}/> 
        <AnimatedWrapper Component={Form}/> 
    </React.Fragment>
  );
};

export default LandingPage;
