import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useTransition, animated } from 'react-spring';
import { motion, AnimatePresence } from 'framer-motion';
import './Navbar.css';

const Navbar = () => {
  const [activeItem, setActiveItem] = useState(null);

  // 1. toggle between black and white nav bar
  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 15) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  // 1. End of Logic


    // Reset active item if clicked elsewhere
    if (!["education", "experience-header", "projects-container", "form-container", "aboutMe-container"].includes(activeItem)) {
      setActiveItem(null);
    }
  
    // Check if linked components are within the viewport
    const checkActiveItem = (target) => {
      const element = document.getElementById(target);
      if (!element) return false; // Return false if the element is null
      const rect = element.getBoundingClientRect();
      return rect.top <= window.innerHeight * 0.2 && rect.bottom >= window.innerHeight * 0.2;
    };
  
    // Set activeItem based on the viewport position
    if (checkActiveItem("education")) {
      setActiveItem("education");
    } else if (checkActiveItem("experience-card")) {
      setActiveItem("experience-card");
    } else if(checkActiveItem("projects-container")){
      setActiveItem("projects-container")
    }else if(checkActiveItem("form-container")){
      setActiveItem("form-container")
    }else if(checkActiveItem("aboutMe-container")){
      setActiveItem("aboutMe-container")
    } else {
      setActiveItem(null); // Reset active item if none are in view
    }
  };  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeItem]);


  // handle scroll into view logic
  const [offset, setOffset] = useState({
    "aboutMe-container": -220,
    "education": -220,
    "projects-container": -220,
    "experience-card": -220,
    "form-container": -220
  });

  // end of logic


  // handle home button click
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // set visibility of menu-container
  const [isVisible, setIsVisible] = useState(false);
  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const hideMenu = () => {
    console.log("HIDEEN")
    setIsVisible(false);
  };

  // end of logic

  return (
    <nav className={`navbar ${scrolling ? 'scrolled' : ''}`}>
      <div className="left" onClick={scrollToTop}>
        <img src="/home_button.gif" alt="a gif" className="homeButton"></img>
      </div>
  
      <div className="center">
      <div className="menu-icon" onClick={toggleMenu}>☰</div>
      <AnimatePresence>
          {isVisible && (
        <motion.div 
        initial={{ opacity: 0, scale: 0, originX: 1, originY: 0 }}
        animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0 }}
        exit={{ opacity: 0, scale: 0, originX: 1, originY: 0 }}
        transition={{ duration: 0.3 }}
        className={`menu-container ${isVisible ? 'visible' : ''}`}
      >
          <div className="menu-item">
            <Link to="aboutMe-container" smooth={true} duration={500} offset={offset["aboutMe-container"]}>
              <div onClick={() => {
                hideMenu()
                let newOffset = { ...offset };
                newOffset["aboutMe-container"] = -130
                setOffset(newOffset)
              }}>About</div>
            </Link>
          </div>
          <div className="menu-item">
            <Link to="education" smooth={true} duration={500} offset={offset["education"]}>
              <div onClick={() => {
                hideMenu()
                let newOffset = { ...offset };
                newOffset["education"] = -130
                setOffset(newOffset)
              }}>Education</div>
            </Link>
          </div>
          <div className="menu-item">
            <Link to="projects-container" smooth={true} duration={500} offset={offset["projects-container"]}>
              <div onClick={() => {
              hideMenu()
              let newOffset = { ...offset };
              newOffset["projects-container"] = -130
              setOffset(newOffset)
              }}>Projects</div>
            </Link>
          </div>
          <div className="menu-item">
            <Link to="experience-content" smooth={true} duration={500} offset={offset["experience-content"]}>
              <div onClick={() => {
              hideMenu()
              let newOffset = { ...offset };
              newOffset["experience-content"] = -200
              setOffset(newOffset)
              }}>Experiences</div>
            </Link>
          </div>
          <div className="menu-item">
            <Link to="form-container" smooth={true} duration={500} offset={offset["form-container"]}>
              <div onClick={() => {
              hideMenu()
              let newOffset = { ...offset };
              newOffset["form-container"] = -130
              setOffset(newOffset)
              }}>Contact</div>
            </Link>
          </div>
        </motion.div>
            )}
      </AnimatePresence>
        <div className={`item visible ${activeItem === "aboutMe-container" ? "active" : ""}`}>
          <Link to="aboutMe-container" smooth={true} duration={500} offset={offset["aboutMe-container"]}>
            <div onClick={() => {
              let newOffset = { ...offset };
              newOffset["aboutMe-container"] = -130
              setOffset(newOffset)
            }}>About</div>
          </Link>
        </div>
          <div className={`item ${activeItem === "education" ? "active" : ""}`}>
          <Link to="education" smooth={true} duration={500} offset={offset["education"]}>
            <div onClick={() => {
              let newOffset = { ...offset };
              newOffset["education"] = -130
              setOffset(newOffset)
            }}>Education</div>
          </Link>
        </div>
        <div className={`item ${activeItem === "projects-container" ? "active" : ""}`}>
          <Link to="projects-container" smooth={true} duration={500} offset={offset["projects-container"]}>
            <div onClick={() => {
             let newOffset = { ...offset };
             newOffset["projects-container"] = -130
             setOffset(newOffset)
            }}>Projects</div>
          </Link>
        </div>
        <div className={`item ${activeItem === "experience-content" ? "active" : ""}`}>
          <Link to="experience-content" smooth={true} duration={500} offset={offset["experience-content"]}>
            <div onClick={() => {
             let newOffset = { ...offset };
             newOffset["experience-content"] = -200
             setOffset(newOffset)
            }}>Experiences</div>
          </Link>
        </div>
        <div className={`item ${activeItem === "form-container" ? "active" : ""}`}>
          <Link to="form-container" smooth={true} duration={500} offset={offset["form-container"]}>
            <div onClick={() => {
             let newOffset = { ...offset };
             newOffset["form-container"] = -130
             setOffset(newOffset)
            }}>Contact</div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
