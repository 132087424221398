import { v4 as uuidv4 } from "uuid";
import Carroussel from "./Carousel";
import LongCard from "../Cards/LongCard";
import "./CarouselMain.css"

function CarouselMain() {
    let cards = [
        {
          key: uuidv4(),
          content: (
            <LongCard title="BudgetBuddy Bot" image="/tele_bot.png" headerColor="#F08080"
              description="telegram bot which I use to manage and track my finances"
              link="https://github.com/aliensarefake/expensesBot"
              skills={[
                { name: 'python', color: '#9ad1d4' },
                { name: 'development', color: '#d2d2d2'},
                { name: 'bot/automation', color: '#badefc'}
              ]}          
              />
          )
        },
        {
          key: uuidv4(),
          content: (
            <LongCard title="SCDF E-Checklist" image="/automateChecklist.png" headerColor="linear-gradient(45deg, rgb(234, 234, 234), rgb(255, 192, 203))"
              description="automated the filling up of the formSG checklists for fire appliances, a task which would normally take an average of 20 minutes when done manually"
              link="https://github.com/aliensarefake/E-Checklist-Automation"
                skills={[
                  { name: 'python', color: '#9ad1d4' },
                  { name: 'bot/automation', color: '#badefc'}
                ]}       
              />
          )
        },
        {
          key: uuidv4(),
          content: (
            <LongCard title="Sudoku Solver" image="sudoku-webapp.png" headerColor="linear-gradient(45deg, rgb(154, 209, 212), rgb(234, 234, 234), rgb(255, 192, 203))"
              description="developed this web app to visualise how the backtracking algorithm navigates through diffferent possibilities to solve the puzzle in a brute-force manner"
              link="https://github.com/aliensarefake/Sudoku-Solver/"
                skills={[
                  { name: 'web app', color: '#f08080' },
                  { name: 'algorithm', color: 'gold'}, 
                  { name: 'development', color: '#d2d2d2'},
                ]}            
              />
          )
        },
        {
          key: uuidv4(),
          content: (
            <LongCard title="NodeFlair Assignment" image="/node-flair.png" headerColor="#F08080"  
              description="In fulfilling the requirements for the take-home assignment, I developed a clone of the NodeFlair website's job section."
              link="https://node-flair-assignment.netlify.app/"
              skills={[
                { name: 'web app', color: '#f08080' },
              ]}/>
          )
        },
        {
          key: uuidv4(),
          content: (
            <LongCard title="Crypto Swap Calculator" image="/crypto.png" headerColor="#F08080"  
              description="• Designed and developed a website that simulates real-time currency conversion between various cryptocurrencies"
              link="https://node-flair-assignment.netlify.app/"
              skills={[
                { name: 'web app', color: '#f08080' },
              ]}/>
          )
        }
      ];

      return (
        <div className="carousel-main">
          <Carroussel
            cards={cards}
            height="500px"
            width="30%"
            margin="0 auto"
            offset={2}
            showArrows={true}
          />
        </div>
      );
}

export default CarouselMain