import React from 'react';
import "./LongCard.css"
import SkillDisplay from '../ProjectPortion/SkillDisplay';


const LongCard = (props) => {
    const headerColor = props.headerColor || "linear-gradient(45deg, rgb(234, 234, 234), rgb(186, 222, 252), rgb(255, 192, 203))";
    const opacityValue = (props.opacity !== undefined) ? props.opacity : 1;

    return (
      <div className='longCard-container' style={{ opacity: opacityValue }}>
        <div className='longCard-header-color' style={{ background: headerColor }}></div>
        <img src={props.image} alt="Image Placeholder" className="longCard-image" style={{ objectFit: 'cover' }}/>
        <div className='longCard-title'>
        {props.link ? (
          <a href={props.link} target="_blank" className="longTitle-text">
            {props.title}
          </a>
        ) : (
          <span className="longTitle-text">
            {props.title}
          </span>
        )}
      </div>
        <div className="longCard-description">
            {props.description}
        </div>
        <div className="card-skills">
          {props.skills && props.skills.map((skill, index) => (
              <SkillDisplay key={index} color={skill.color} skillName={skill.name} />
          ))}
        </div>
      </div>
    );
  };
  
  export default LongCard;
  