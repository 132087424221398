import "./AboutMeCard.css";
import React from "react";
import * as Unicons from '@iconscout/react-unicons';



const AboutMeCard = (props) => {

    return(
    <div className="aboutMeCard-container">
        <div className="logo">
            {props.iconElement}
        </div>
        <h3 className="aboutMeCard-title">
            {props.title}
        </h3>
        <span className="aboutMeCard-subtitle">
            {props.subtitle}
        </span>
    </div>
    )

} 

export default AboutMeCard