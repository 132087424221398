import './App';
import React from 'react';
import Navbar from './LandingPage/Navbar';
import Footer from './LandingPage/Footer/Footer';
import LandingPage from './LandingPage/LandingPage';
import ScrollUpButton from './ScrollUpButton';


function App() { // New component to wrap the content inside the Router
  return (
    <div id='hhi'>
      <Navbar />
      <ScrollUpButton/>
      <LandingPage/>
      <Footer />
    </div>
  );
}


export default App;
