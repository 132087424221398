import React, { useState, useEffect } from 'react';
import './Footer.css'; // Importing the CSS file
import GithubLogo from "./github.svg" 
import LinkedinLogo from "./linkedin.svg"
import LeetcodeLogo from "./leetcode.svg"
import Wave from 'react-wavify'

const Footer = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    // Handler to set the new window size when resized
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

    return (
      <footer className="footer">
        <p>Made with <span role="img" aria-label="love">☕</span> by Alex Guo</p>
        <div className="social-links">
          <a href="https://github.com/aliensarefake/" target="_blank" rel="noopener noreferrer">
            <img src={GithubLogo} alt="GitHub" /> {/* Using the imported GitHub SVG */}
          </a>
          <a href="https://www.linkedin.com/in/guo-xiao-yu/" target="_blank" rel="noopener noreferrer">
            <img src={LinkedinLogo} alt="LinkedIn" /> {/* Using the imported LinkedIn SVG */}
          </a>
          <a href="https://leetcode.com/asfakeasaliens/" target="_blank" rel="noopener noreferrer">
            <img src={LeetcodeLogo} alt="LeetCode" /> {/* Using the imported LeetCode SVG */}
          </a>
        </div>
        <Wave fill='#DCDCDC'
        paused={false}
        style={{ display: windowSize < 800 ? 'none' : 'flex',  position: 'absolute', bottom: '0', zIndex:'-1'}}
        options={{
          height: 10,
          amplitude: 20,
          speed: 0.3,
          points: 3,
        }}
  />
      </footer>
    );
  };

export default Footer;
