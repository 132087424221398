import React from 'react';
import './Greeting.css';

const Greeting = () => {
  return (
    <div className="greeting-container">
      <div className="greeting-text">
        <span className="welcome-text">👋 Hey there! I'm</span>
        <span className="name-text">Alex Guo</span>
      </div>
    </div>
  );
};

export default Greeting;
